import React, { useState } from 'react';
import axios from 'axios';
import './Login.css'; // Make sure to create this CSS file for styling
import logo from './assets/logo.png'; 

function Login() {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/api/login', { name, password });
      localStorage.setItem('token', response.data.token);
      // Redirect or handle successful login
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="login-container">
      <img src={logo} alt="Airbnb Logo" className="logo" />
      <h1>Sign in to continue</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" className="login-button">Log In</button>
      </form>
    </div>
  );
}

export default Login;
